<style lang="less" scoped>
@import "./ContactUs.less";
</style>

<template>
  <Card dis-hover>
    <Row type="flex" justify="space-around">
      <Col span="8">
        <!-- <Row class="logo">
          <img src="../../assets/logo.jpg" />
        </Row> -->
        <Row class="contact-details">
          <h3 style="margin-bottom: 24px"><Icon type="ios-call" /> Wechat</h3>
          <p>莫纳什客服微信: homedeilvery</p>
          <p>墨大客服微信: Lemon_arrived</p>
          <!-- <p>
            Email:
            <a href="mailto:customerservice@aucourse.com">
              customerservice@aucourse.com
            </a>
          </p> -->

          <!-- <h3><Icon type="ios-clock" /> Trading Hours</h3>
          <p>MON – SUN 24hrs</p>
          <h3><Icon type="ios-heart" /> Follow US</h3> -->
        </Row>
      </Col>
    </Row>
  </Card>
</template>
<script>
export default {
  data: function () {
    return {
      onLoading: true
    };
  },
  methods: {
    removeLoading() {
      this.onLoading = false;
    }
  }
};
</script>
